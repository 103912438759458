import ProjectsList from "./ProjectsList";
import StanwarsVideo from "../../assets/video/stanwars.mp4";

function ProjectSection() {
  return (
    <section className="section-projects">
      <div className="container">
        <h2 className="heading-secondary heading-secondary--bold heading-secondary--arrow u_margin-bottom-xxl">
          Featured projects{" "}
          <div className="arrow arrow--inline u_hide-on-tab"></div>
        </h2>
        <div className="u_margin-bottom-l">
          <ProjectsList />
        </div>

        <h2 className="heading-secondary heading-secondary--bold heading-secondary--arrow u_margin-bottom-xxl u_margin-top-xxl">
          Other projects{" "}
          <div className="arrow arrow--inline u_hide-on-tab"></div>
        </h2>
        <div className="project project--center">
          <div className="details__main-content">
            <div className="stanwars-video-container">
              <video className="details__video" muted autoPlay playsInline loop>
                <source src={StanwarsVideo} type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="details__support-content">
            <h3 className="project__name project__name--small">
              stan wars: the rise of a new army
            </h3>
            <p className="body-text--small">
              A collection of interactive data visualizations about stan groups
              and the phenomenon of hashtag hijacking, made by my team for the
              Final Synthesis Design Studio course at Politecnico di Milano.
            </p>
            <a href="https://dd16-group2.github.io/stan-wars/" target="blank">
              <span className="button button--link button--secondary u_margin-top-l">
                Launch project{" "}
                <div className="arrow arrow--inline arrow--horizontal "></div>
              </span>
            </a>
          </div>
        </div>
        <div className="details__content project project--center">
          <div className="details__main-content">
            <div className="youtube-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/PJoMqvhoWWg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </div>
          </div>
          <div className="details__support-content">
            <h3 className="project__name project__name--small">
              motion graphics
            </h3>
            <p className="body-text--small">
              A showreel collecting some of the animation and motion graphics
              projects I did as a freelance designer while pursuing my master's
              degree.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectSection;
