import React from "react";
import PhoneIcon from "../../assets/icons/phone.svg";
import EmailIcon from "../../assets/icons/email.svg";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__contacts-container">
          <div className="footer__contact">
            <img
              src={PhoneIcon}
              alt="telephone"
              className="footer__icon footer__icon--phone"
            />{" "}
            +39 3663628388
          </div>
          <div className="footer__separator"></div>
          <div className="footer__contact">
            <img src={EmailIcon} alt="email" className="footer__icon" />
            dileonardo.lorenzo@gmail.com
          </div>
        </div>

        <div className="footer__copyright">&copy; Lorenzo Di Leonardo 2022</div>
      </div>
    </footer>
  );
}

export default Footer;
